export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは4月より%d点上がりました。五月病が心配される時期に素晴らしいです。<br>
      6月1日から<b>Walk 個人チャレンジ 2022 1st</b>、6月13日からは<b>ウェル知 2022 1st</b>が始まります！<br>
      楽しみながら「ウォーキングルーティーン」を身につけましょう。<br>
      ウェル知は20問出題！何問正解できるかな？`,
      down: `生活習慣スコアは4月より%d点下がりました。分類別評価でどの項目に変化があったのか見てみましょう。<br>
      6月1日から<b>Walk 個人チャレンジ 2022 1st</b>、6月13日からは<b>ウェル知 2022 1st</b>が始まります！<br>
      楽しみながら「ウォーキングルーティーン」を身につけましょう。<br>
      ウェル知は20問出題！何問正解できるかな？`,
      equal100: `生活習慣スコアは4月に引き続き<span class="good">満点</span>です。五月病が心配される時期に大変素晴らしいです！<br>
      6月1日から<b>Walk 個人チャレンジ 2022 1st</b>、6月13日からは<b>ウェル知 2022 1st</b>が始まります！<br>
      楽しみながら「ウォーキングルーティーン」を身につけましょう。<br>
      ウェル知は20問出題！何問正解できるかな？`,
      equal: `生活習慣スコアは4月と同じ点数です。分類別評価で、項目別に振り返ってみましょう。<br>
      6月1日から<b>Walk 個人チャレンジ 2022 1st</b>、6月13日からは<b>ウェル知 2022 1st</b>が始まります！<br>
      楽しみながら「ウォーキングルーティーン」を身につけましょう。<br>
      ウェル知は20問出題！何問正解できるかな？`,
    },
    steps: {
      up: `歩数スコアは4月より%d点上がりました。<br>
      <a href="https://go.andwell.jp/library/e914ef1d-5e3a-11ec-9728-06d158a16ffc">こちらのコラム</a>を読んで、早歩きをするためのポイントやメリットをチェック！<br>
      ぜひ実践してみましょう。`,
      down: `歩数スコアは4月より%d点下がりました。<br>
      <a href="https://go.andwell.jp/library/e914ef1d-5e3a-11ec-9728-06d158a16ffc">こちらのコラム</a>を読んで、早歩きをするためのポイントやメリットをチェック！<br>
      ぜひ実践してみましょう。`,
      equal: `歩数スコアは4月と同じです。<br>
      <a href="https://go.andwell.jp/library/e914ef1d-5e3a-11ec-9728-06d158a16ffc">こちらのコラム</a>を読んで、早歩きをするためのポイントやメリットをチェック！<br>
      ぜひ実践してみましょう。`,
    },
    exercise: {
      up: `運動のスコアは4月より%d点上がりました。<br>
      雨が多くなる時期は、テレビを見ながら<b>踏み台昇降</b>など室内でできる運動もオススメです。`,
      down: `運動のスコアは4月より%d点下がりました。<br>
      雨が多くなる時期は、テレビを見ながら<b>踏み台昇降</b>など室内でできる運動もオススメです。`,
      equal: `運動のスコアは4月と同じです。<br>
      雨が多くなる時期は、テレビを見ながら<b>踏み台昇降</b>など室内でできる運動もオススメです。`,
    },
    meal: {
      up: `食事のスコアは4月より%d点上がりました。<br>
      実は湿度が高くなる梅雨の時期から<b>熱中症</b>に注意が必要！<br>
      こまめな水分補給を心がけてください。`,
      down: `食事のスコアは4月より%d点下がりました。<br>
      実は湿度が高くなる梅雨の時期から<b>熱中症</b>に注意が必要！<br>
      こまめな水分補給を心がけてください。`,
      equal: `食事のスコアは4月と同じです。<br>
      実は湿度が高くなる梅雨の時期から<b>熱中症</b>に注意が必要！<br>
      こまめな水分補給を心がけてください。`,
    },
    drinking: {
      up: `飲酒のスコアは4月より%d点上がりました。<br>
      <a href="https://go.andwell.jp/library/d5c50efb-72ae-11ec-9ce8-0af363eb5062">こちらの動画</a>では健康にお酒を楽しむためのポイントを紹介。<br>
      特に二日酔いや悪酔いにしてしまう方にオススメ！`,
      down: `飲酒のスコアは4月より%d点下がりました。<br>
      <a href="https://go.andwell.jp/library/d5c50efb-72ae-11ec-9ce8-0af363eb5062">こちらの動画</a>では健康にお酒を楽しむためのポイントを紹介。<br>
      特に二日酔いや悪酔いにしてしまう方にオススメ！`,
      equal: `飲酒のスコアは4月と同じです。<br>
      <a href="https://go.andwell.jp/library/d5c50efb-72ae-11ec-9ce8-0af363eb5062">こちらの動画</a>では健康にお酒を楽しむためのポイントを紹介。<br>
      特に二日酔いや悪酔いにしてしまう方にオススメ！`,
    },
    sleep: {
      up: `睡眠のスコアは4月より%d点上がりました。<br>
      ジメジメして寝苦しくなる時期になりますね。<br>
      換気をしたり、エアコンで除湿をして、湿度対策！`,
      down: `睡眠のスコアは4月より%d点下がりました。<br>
      ジメジメして寝苦しくなる時期になりますね。<br>
      換気をしたり、エアコンで除湿をして、湿度対策！`,
      equal: `睡眠のスコアは4月と同じです。<br>
      ジメジメして寝苦しくなる時期になりますね。<br>
      換気をしたり、エアコンで除湿をして、湿度対策！`,
    },
    stress: {
      up: `ストレスのスコアは4月より%d点上がりました。<br>
      寒暖差やどんよりした曇り空が続くのもストレスの原因の１つです。<br>
      生活リズムを整えることを意識しましょう。`,
      down: `ストレスのスコアは4月より%d点下がりました。<br>
      寒暖差やどんよりした曇り空が続くのもストレスの原因の１つです。<br>
      生活リズムを整えることを意識しましょう。`,
      equal: `ストレスのスコアは4月と同じです。<br>
      寒暖差やどんよりした曇り空が続くのもストレスの原因の１つです。<br>
      生活リズムを整えることを意識しましょう。`,
    },
  },
  en: {
    score: {
      up: `Your lifestyle score has gone up %d pts since April. That's a fantastic achievement for the "May Blues" season. <br>
      <b>Walk Individual Challenge 2022 1st Round</b> begins on June 1st, followed by <b>Well-zhi 2022 1st Round</b> on June 13th! <br>
      Enjoy yourself while making walking part of your routine. <br>
      Well-zhi will have 20 questions! How many will you get right?`,
      down: `Your lifestyle score has gone down %d pts since April. Use the categorized ratings to see what items have changed.<br>
      <b>Walk Individual Challenge 2022 1st Round</b> begins on June 1st, followed by <b>Well-zhi 2022 1st Round</b> on June 13th! <br>
      Enjoy yourself while making walking part of your routine. <br>
      Well-zhi will have 20 questions! How many will you get right?`,
      equal100: `Your lifestyle score is the <span class="good">maximum</span> again, the same as April. That's a really fantastic achievement for the "May Blues" season!<br>
      <b>Walk Individual Challenge 2022 1st Round</b> begins on June 1st, followed by <b>Well-zhi 2022 1st Round</b> on June 13th! <br>
      Enjoy yourself while making walking part of your routine. <br>
      Well-zhi will have 20 questions! How many will you get right?`,
      equal: `Your lifestyle score is the same as April. Use the categorized ratings to review your progress item by item.<br>
      <b>Walk Individual Challenge 2022 1st Round</b> begins on June 1st, followed by <b>Well-zhi 2022 1st Round</b> on June 13th! <br>
      Enjoy yourself while making walking part of your routine. <br>
      Well-zhi will have 20 questions! How many will you get right?`,
    },
    steps: {
      up: `Your steps score has gone up %d pts since April. <br>
      <a href="https://go.andwell.jp/library/e914ef1d-5e3a-11ec-9728-06d158a16ffc">Read this column</a> to see the key points for walking fast, and the benefits it brings! <br>
      You should definitely try it yourself.`,
      down: `Your steps score has gone down %d pts since April. <br>
      <a href="https://go.andwell.jp/library/e914ef1d-5e3a-11ec-9728-06d158a16ffc">Read this column</a> to see the key points for walking fast, and the benefits it brings! <br>
      You should definitely try it yourself.`,
      equal: `Your steps score is the same as April. <br>
      <a href="https://go.andwell.jp/library/e914ef1d-5e3a-11ec-9728-06d158a16ffc">Read this column</a> to see the key points for walking fast, and the benefits it brings! <br>
      You should definitely try it yourself.`,
    },
    exercise: {
      up: `Your exercise score has gone up %d pts since April. <br>
      In the rainy season, we also recommend exercise you can do indoors, like <b>step aerobics</b> while watching TV.`,
      down: `Your exercise score has gone down %d pts since April. <br>
      In the rainy season, we also recommend exercise you can do indoors, like <b>step aerobics</b> while watching TV.`,
      equal: `Your exercise score is the same as April. <br>
      In the rainy season, we also recommend exercise you can do indoors, like <b>step aerobics</b> while watching TV.`,
    },
    meal: {
      up: `Your food score has gone up %d pts since April.<br>
      In fact, once the humidity starts in the rainy season, you'll also need to be careful about <b>heat stroke</b>! <br>
      Make sure you rehydrate regularly.`,
      down: `Your food score has gone down %d pts since April.<br>
      In fact, once the humidity starts in the rainy season, you'll also need to be careful about <b>heat stroke</b>! <br>
      Make sure you rehydrate regularly.`,
      equal: `Your food score is the same as April.<br>
      In fact, once the humidity starts in the rainy season, you'll also need to be careful about <b>heat stroke</b>! <br>
      Make sure you rehydrate regularly.`,
    },
    drinking: {
      up: `Your alcohol score has gone up %d pts since April. <br>
      <a href="https://go.andwell.jp/library/d5c50efb-72ae-11ec-9ce8-0af363eb5062">This video</a> shows you the key points for drinking alcohol in a healthy way. <br>
      We particularly recommend it if you're prone to getting a hangover or upset stomach!`,
      down: `Your alcohol score has gone down %d pts since April. <br>
      <a href="https://go.andwell.jp/library/d5c50efb-72ae-11ec-9ce8-0af363eb5062">This video</a> shows you the key points for drinking alcohol in a healthy way. <br>
      We particularly recommend it if you're prone to getting a hangover or upset stomach!`,
      equal: `Your alcohol score is the same as April. <br>
      <a href="https://go.andwell.jp/library/d5c50efb-72ae-11ec-9ce8-0af363eb5062">This video</a> shows you the key points for drinking alcohol in a healthy way. <br>
      We particularly recommend it if you're prone to getting a hangover or upset stomach!`,
    },
    sleep: {
      up: `Your sleep score has gone up %d pts since April. <br>
      We're heading into the season of restless, humid nights. <br>
      Beat the humidity by ventilating the room and using the air conditioning to dehumidify!`,
      down: `Your sleep score has gone down %d pts since April. <br>
      We're heading into the season of restless, humid nights. <br>
      Beat the humidity by ventilating the room and using the air conditioning to dehumidify!`,
      equal: `Your sleep score is the same as April. <br>
      We're heading into the season of restless, humid nights. <br>
      Beat the humidity by ventilating the room and using the air conditioning to dehumidify!`,
    },
    stress: {
      up: `Your stress score has gone up %d pts since April.<br>
      The incessant cloudy skies and changing between hot and cold are another cause of stress. <br>
      Make a conscious effort to maintain a steady lifestyle rhythm.`,
      down: `Your stress score has gone down %d pts since April.<br>
      The incessant cloudy skies and changing between hot and cold are another cause of stress. <br>
      Make a conscious effort to maintain a steady lifestyle rhythm.`,
      equal: `Your stress score is the same as April.<br>
      The incessant cloudy skies and changing between hot and cold are another cause of stress. <br>
      Make a conscious effort to maintain a steady lifestyle rhythm.`,
    },
  },
}
